
import { Services } from 'tradingmate-modules/src/services'
import { Vue, Component, Prop } from 'vue-property-decorator'
import AutoCompleter, { AutocompleteProvider } from '@/components/inputs/AutoCompleter.vue'
import { AutocompleteResult } from '@/components/inputs/AutocompleteResult'
import BusinessBranchOrCategorySearchResult from 'tradingmate-modules/src/models/api/search/BusinessBranchOrCategorySearchResult'
import BusinessBranchOrCategorySearchResultCard from '@/components/search/BusinessBranchOrCategorySearchResultCard.vue'
import CategoryCard from '@/components/collections/category/CategoryCard.vue'

export interface CategoryAutocompleteResult extends AutocompleteResult {
  BusinessBranchOrCategorySearchResult: BusinessBranchOrCategorySearchResult;
}

@Component({
  components: {
    CategoryCard,
    BusinessBranchOrCategorySearchResultCard,
    AutoCompleter
  }
})
export default class CategorySelector extends Vue {
  // Results

  // public searchTerm = ''
  public minTermLength = 3;
  private loading = false

  private categoryProvider: AutocompleteProvider<CategoryAutocompleteResult> = {
    Search: function (term: string): Promise<CategoryAutocompleteResult[]> {
      return (Services.API.Categories.Autocomplete(term)
        .then((returnModels) => {
          return returnModels.map((result) => {
            return {
              Label: result.Title,
              Value: result.Id,
              BusinessBranchOrCategorySearchResult: result
            } as CategoryAutocompleteResult
          }) as CategoryAutocompleteResult[]
        })
      )
    },
    Suggest: function (term: string): Promise<string|null> {
      throw new Error(`Function not implemented. Term: ${term}`)
    }
  }

  // Selections
  // v-model a category model array
  @Prop({ required: true })
  private readonly value!: BusinessBranchOrCategorySearchResult[];

  onResultSelected (selection: CategoryAutocompleteResult): void {
    if (selection.BusinessBranchOrCategorySearchResult.BranchSlug) {
      console.log('onCardSelected')
      this.$router.push({
        name: 'Member Details',
        params: { memberSlug: selection.BusinessBranchOrCategorySearchResult.BranchSlug }
      })
    }
    const category = selection.BusinessBranchOrCategorySearchResult
    const alreadyInCategories = this.value?.find((val) => val.Id === category.Id)
    if (alreadyInCategories !== undefined) {
      return
    }
    const newValue = [...this.value, category]
    this.$emit('input', newValue)
  }

  removeCategory (id: string): void {
    if (!id) return
    const index = this.value?.findIndex((item) => item.Id === id)
    if (index === -1) {
      console.error('Category index not found')
      return
    }
    const newValue = this.value
    newValue.splice(index, 1)
    this.$emit('input', newValue)
  }

  onSearchTermUpdated (searchTerm: string): void {
    this.$emit('searchTermUpdated', searchTerm)
  }
}
