
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import SearchBar from '@/components/search/SearchBar.vue'
import { CategorySelector, ZoneSelector } from '@/components/inputs'
import { SearchBarItem } from '@/components/search/index'
import BusinessBranchOrCategorySelector from '@/components/search/BusinessBranchOrCategorySelector.vue'
import { ZoneModel } from '../../../../tradingmate-modules/src/models/api/admin'
import { CategoryModel } from '../../../../tradingmate-modules/src/models/api/categorisation'
import BusinessBranchOrCategorySearchResult
  from '../../../../tradingmate-modules/src/models/api/search/BusinessBranchOrCategorySearchResult'
import BusinessBranchSearchResponse
  from '../../../../tradingmate-modules/src/models/api/businesses/BusinessBranchSearchResponse'

@Component({
  components: {
    SearchBar,
    SearchBarItem,
    CategorySelector,
    ZoneSelector,
    BusinessBranchOrCategorySelector
  }
})

export default class WholeSearchBar extends Vue {
  // tradingmates who need your services
  private tmCategories: CategoryModel[] = []
  private tmZones: ZoneModel[] = []
  private tmLoading = false

  // Find businesses you need
  private bbocr: BusinessBranchOrCategorySearchResult[] = []
  private zones: ZoneModel[] = []
  private searchQuery = ''
  private loading = false

  @Prop({ default: null })
  private readonly searchResponse!: BusinessBranchSearchResponse | null

  @Watch('searchResponse')
  handleSearchResponse (): void {
    if (this.searchResponse?.NeedsMyServices) {
      if (!this.tmZones.length && this.searchResponse?.Zone != null) {
        this.tmZones = [this.searchResponse.Zone]
      }

      if (!this.tmCategories.length && this.searchResponse?.SearchResult != null) {
        const category: CategoryModel = {
          CategoryId: this.searchResponse.SearchResult.Id,
          Name: this.searchResponse.SearchResult.Title,
          Icon: this.searchResponse.SearchResult.Icon,
          Eyebrow: this.searchResponse.SearchResult.Eyebrow,
          Parent: null
        }
        this.tmCategories = [category]
      }
    } else {
      if (this.searchQuery === '' && this.searchResponse?.BusinessSearch != null) {
        this.searchQuery = this.searchResponse.BusinessSearch
      }
      if (!this.zones.length && this.searchResponse?.Zone != null) {
        this.zones = [this.searchResponse.Zone]
      }
      if (this.bbocr.length && this.searchResponse?.SearchResult != null) {
        this.bbocr = [this.searchResponse?.SearchResult]
      }
    }
  }

  // onNonProfitSupport
  onNonProfitSupport (): void {
    this.$router.push({
      path: '/find-a-business',
      query: {
        categories: undefined,
        zones: undefined,
        mwl: undefined,
        search: undefined,
        np: 'true'
      }
    })
  }

  tmSearch (): void {
    this.bbocr = []
    this.zones = []
    this.tmLoading = true
    const categoryIds = this.tmCategories.map((x) => { return x.CategoryId })
    const zoneIds = this.tmZones.map((x) => { return x.ZoneId })

    this.$router.push({
      path: '/find-a-business',
      query: {
        categories: categoryIds ?? undefined,
        zones: zoneIds ?? undefined,
        mwl: 'true',
        search: undefined,
        np: undefined
      }
    })
    this.tmLoading = false
  }

  search (): void {
    this.tmCategories = []
    this.tmZones = []
    this.searchQuery = ''

    const categoryIds = this.bbocr.map((item) => { return item.Id })
    const zoneIds = this.zones.map((item) => { return item.ZoneId })
    this.loading = true

    this.$router.push({
      path: '/find-a-business',
      query: {
        categories: categoryIds ?? undefined,
        zones: zoneIds ?? undefined,
        search: this.searchQuery.length ? this.searchQuery : undefined,
        mwl: undefined,
        np: undefined
      }
    })

    this.loading = false
  }
}

