
import { Vue, Component, Prop } from 'vue-property-decorator'
import BusinessBranchOrCategorySearchResult
  from 'tradingmate-modules/src/models/api/search/BusinessBranchOrCategorySearchResult'

@Component({
  components: {
  }
})

export default class BusinessBranchOrCategorySearchResultCard extends Vue {
  @Prop({ required: true })
  private readonly result!: BusinessBranchOrCategorySearchResult;
}

