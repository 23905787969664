
import Card from '@/components/cards/Card.vue'
import Badge from '@/components/status/Badge.vue'
import CategoryCard from '@/components/collections/category/CategoryCard.vue'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Config } from 'tradingmate-modules/src/environment'
import BusinessBranchCard from '../../../../../tradingmate-modules/src/models/api/businesses/BusinessBranchCard'
import { CategoryModel } from '../../../../../tradingmate-modules/src/models/api/categorisation'

@Component({
  components: {
    CategoryCard,
    Card,
    Badge
  }
})
export default class MemberCard extends Vue {
  @Prop({ required: true })
  private readonly businessBranchModel!: BusinessBranchCard

  @Prop({ default: null })
  private readonly preferredCategoryIds!: string[] | null

  private config = Config

  get thumbnail (): string {
    let thumb = '/images/brand/placeholder.jpg'
    if (this.businessBranchModel.Logo?.Tiny) {
      thumb = this.businessBranchModel.Logo.Tiny
    } else if (this.businessBranchModel.GalleryImages && this.businessBranchModel.GalleryImages[0]?.Tiny) {
      thumb = this.businessBranchModel.GalleryImages[0].Tiny
    }
    return thumb
  }

  get preferredCategory (): CategoryModel | null {
    const intersection = this.businessBranchModel.Categories.filter(value => this.preferredCategoryIds?.includes(value.CategoryId))
    if (intersection.length) {
      return intersection[0]
    } else if (this.businessBranchModel.Categories[0]) {
      return this.businessBranchModel.Categories[0]
    } else {
      return null
    }
  }
}
